 window.addEventListener('load', function () {
   const archiveDropdown = document.querySelector(".archive-dropdown");
   const archiveMenu = archiveDropdown.querySelector(".archive-menu");

   archiveDropdown.addEventListener("mouseenter", function () {
     archiveMenu.style.display = "block";
   });

   archiveDropdown.addEventListener("mouseleave", function () {
     archiveMenu.style.display = "none";
   });

   window.addEventListener("scroll", function () {
     var nav = document.querySelector(".nav");
     nav.classList.toggle("scrolled", window.scrollY > 40);
   });

 

   function scrollToElementWithOffset(element, offset) {
     const elementPosition = element.offsetTop - offset;
     window.scrollTo({
       top: elementPosition,
       behavior: 'smooth'
     });
   }

   document.querySelectorAll('.mobile-links a').forEach(function (link) {
     link.addEventListener('click', function (event) {
       event.preventDefault(); // Prevent default anchor behavior
       const targetId = this.getAttribute('href').substring(1); // Get the target element ID
       const targetElement = document.getElementById(targetId);

       if (targetElement) {
         setTimeout(function () {
           document.getElementById('burger').checked = false;
           document.querySelector('.mobile-links').style.display = 'none';
           document.querySelector('.block-header').style.background = '';
           document.querySelector('.block-header').style.maxHeight = '100vh';
           document.querySelector('.block-header').style.height = 'fit-content';
         }, 1000);

         let offset = 20;

         // Check if the target element is the "download" element
         if (targetId === 'download') {
           // Adjust the offset for the "download" element by 100px
           offset += 300;
         }

         scrollToElementWithOffset(targetElement, offset);
       }
     });
   });


   var accordions = document.querySelectorAll('.accordion');

   accordions.forEach(function (accordion) {
     accordion.addEventListener('click', function () {
       this.classList.toggle('active');
       var panel = this.nextElementSibling;

       if (panel.style.maxHeight) {
         panel.style.maxHeight = null;
       } else {
         panel.style.maxHeight = panel.scrollHeight + 'px';
       }
     });
   });
 });

 window.addEventListener('resize', function () {

 }, {
   passive: true
 })


 document.addEventListener('scroll', function () {

 }, {
   passive: true
 })


 $(document).ready(function () {
   $(".accordion").click(function () {
     $(this).toggleClass("active");

     var panel = $(this).next();

     if (panel.is(":visible")) {
       panel.slideUp("slow");
     } else {
       panel.slideDown("slow");
     }
   });
 });


 document.getElementById('burger').addEventListener('change', function () {
  if (this.checked) {
    document.querySelector('.mobile-links').style.display = 'block';
    document.querySelector('.block-header').style.background = '#000000e3';

    document.querySelector('.block-header').style.overflowY = "scroll";
    document.querySelector('.block-header').style.height = '100%';
  } else {
    document.querySelector('.mobile-links').style.display = 'none';
    document.querySelector('.block-header').style.overflowY = "hidden";
    document.querySelector('.block-header').style.background = '';

    document.querySelector('.block-header').style.maxHeight = '100vh';
    document.querySelector('.block-header').style.height = 'fit-content';

  }
});

var links = document.querySelectorAll('.mobile-links a');
for (var i = 0; i < links.length; i++) {
  links[i].addEventListener('click', function() {
    document.getElementById('burger').checked = false;
    var changeEvent = new Event('change');
    document.getElementById('burger').dispatchEvent(changeEvent);
  });
}